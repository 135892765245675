import React, { useState, useEffect } from "react";
import LoaderPage from "../LoaderPage";
import { postMetadata, postPassword } from "../../utils";
import passwordImg from "../../assets/password.png";
import { colors, ErrorStyle } from "../../styles";
import { TextInput } from "../Inputs";
import Button from "../Button";
import CONFIG from "../../CONFIG";

const { primary, textInvertColor } = colors;

const FormStyle = {
  backgroundColor: primary,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  color: textInvertColor,
};

const LabelStyle = {
  display: "flex",
  flexDirection: "column",
  maxWidth: 500,
  width: "80%",
  minWidth: 300,
  alignItems: "stretch",
  justifyContent: "center",
};

const IconStyle = {
  width: 100,
  margin: "10px auto",
};

const PasswordProtect = ({ id, setPassword: setCorrectPassword }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const initialize = async () => await postMetadata({ msg: `Password page` });
    initialize();
  }, []);

  const submitForm = async (pass) => {
    if (!pass) return setError(true);
    setPassword("");
    setSubmitting(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const [_, passRes] = await Promise.all([
        postMetadata({ msg: `Password attempt - ${pass}` }),
        postPassword({ id, password: pass }),
      ]);
      const {
        data: { password: PASSWORD_CORRECT },
      } = passRes;
      if (PASSWORD_CORRECT) {
        await postMetadata({ msg: `Password correct  - ${pass}` });
        setCorrectPassword(pass);
      } else {
        await postMetadata({ msg: `Password incorrect - ${pass}` });
      }
    } catch (err) {
      await postMetadata({ msg: `Error checking password - ${err}` });
      setError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const formSubmission = (e) => {
    e.preventDefault();
    submitForm(password);
  };

  return submitting ? (
    <LoaderPage />
  ) : (
    <form style={FormStyle} onSubmit={formSubmission}>
      <label style={LabelStyle}>
        <img src={passwordImg} style={IconStyle} alt="Password icon" />
        <h2>{CONFIG.PASSWORD_PROTECT.HEADER}</h2>
        <TextInput
          autoFocus
          placeholder="Password"
          style={error ? { border: `1px solid ${colors.error}` } : {}}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" onClick={formSubmission}>
          Submit
        </Button>
        {error && !password && (
          <span style={{ ...ErrorStyle, margin: 10 }}>
            {CONFIG.PASSWORD_PROTECT.ERROR}
          </span>
        )}
      </label>
    </form>
  );
};

export default PasswordProtect;
