import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { postMetadata } from "../../utils";

// HOC to post metadata on route change
const Instrumented = ({ children, location }) => {
  const pageName = location.pathname;
  useEffect(() => {
    const initialize = async () =>
      await postMetadata({ msg: `${pageName} route visited` });
    initialize();
  }, [pageName]);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(Instrumented);
