import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoaderPage from "../LoaderPage";
import { postMetadata } from "../../utils";
import geo from "../../assets/geo.png";
import { colors } from "../../styles";
import CONFIG from "../../CONFIG";
import Geolocator from "../Geolocator";

const {
  DEFAULT_MESSAGE,
  USER_DENIED,
  TIMEOUT,
  UNAVAILABLE,
  UNSUPPORTED,
} = CONFIG.GEOPROTECT;

const { GLOBAL } = CONFIG;

const { primary, textInvertColor } = colors;

const GeoProtectStyle = {
  backgroundColor: primary,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  color: textInvertColor,
  fontSize: "1.3rem",
};

const GeoIconStyle = {
  width: 200,
};

const GeoTextStyle = {
  width: "80%",
  maxWidth: "800px",
  margin: 10,
};

const GeoProtect = ({ setGeolocation: setCollectedGeo, requiredAccuracy }) => {
  const [error, setError] = useState(null);
  const [geolocation, setGeolocation] = useState(null);
  const [msg, setMessage] = useState(DEFAULT_MESSAGE);
  const [loading, setLoading] = useState(false);

  // INITIALIZE
  useEffect(() => {
    const initialize = async () =>
      await postMetadata({
        msg: `GeoProtect initialize`,
        navigatorSupported: !!navigator.geolocation,
      });
    initialize();
  }, []);

  // CLIENT ERROR
  useEffect(() => {
    // handles setting client facing messages
    if (!error) {
      return;
    } else if (error.code === 1) {
      setMessage(USER_DENIED);
    } else if (error.code === 2) {
      setMessage(UNAVAILABLE);
    } else if (error.code === 3) {
      setMessage(TIMEOUT);
    } else if (error.code === 4) {
      setMessage(UNSUPPORTED);
    }
  }, [error]);

  // POST ERROR
  useEffect(() => {
    const sendError = async (error) =>
      await postMetadata({
        msg: `Geoprotect error`,
        body: error,
        navigatorSupported: !!navigator.geolocation,
      });

    if (error) sendError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // SUCCESS
  useEffect(() => {
    const confirmed = async () => {
      await postMetadata({
        msg: `Geoprotect success`,
        body: geolocation,
        navigatorSupported: !!navigator.geolocation,
      });

      // if accuracy is high enough, set geolocation and allow retrieval
      if (
        geolocation.accuracy <= (requiredAccuracy || GLOBAL.REQUIRED_ACCURACY)
      ) {
        setCollectedGeo(geolocation);
      }
    };

    if (geolocation) confirmed();
  }, [geolocation, setCollectedGeo, requiredAccuracy]);

  return (
    <Geolocator
      setLoading={setLoading}
      setError={setError}
      setGeolocation={setGeolocation}
      retries={5}
      requiredAccuracy={requiredAccuracy}
    >
      {loading ? (
        <LoaderPage />
      ) : (
        <div style={GeoProtectStyle}>
          <img src={geo} alt="geo icon" style={GeoIconStyle} />
          <span style={GeoTextStyle}>{msg}</span>
          {msg === DEFAULT_MESSAGE
            ? CONFIG.GEOPROTECT.WHY()
            : CONFIG.GEOPROTECT.TROUBLE_SHOOTING()}
        </div>
      )}
    </Geolocator>
  );
};

GeoProtect.propTypes = {
  setGeolocation: PropTypes.func.isRequired,
};

export default GeoProtect;
