import React, { useState, useEffect } from "react";
import { default as LoaderComponent } from "react-loader-spinner";

import { colors } from "../../styles";

const { accent, highlight } = colors;

const Loader = ({ type = "BallTriangle", ...rest }) => {
  const [color, setColor] = useState(highlight);

  useEffect(() => {
    const colorChangeTimer = () =>
      setTimeout(() => {
        if (color === highlight) setColor(accent);
        else setColor(highlight);
      }, 500);
    colorChangeTimer();
    return () => clearTimeout(colorChangeTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoaderComponent
      type={type}
      color={color}
      height={200}
      width={200}
      {...rest}
    />
  );
};

export default Loader;
