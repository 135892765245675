import React from "react";
import Footer from "../components/Footer";
import { AboutPageStyle, ContentStyle } from "../styles";
import CONFIG from "../CONFIG";

const Privacy = () => (
  <div style={AboutPageStyle}>
    <h1>{CONFIG.PRIVACY_PAGE.HEADER}</h1>
    <span>
      <b>Last Updated:</b> {CONFIG.PRIVACY_PAGE.UPDATED_TIME}
    </span>
    <div style={ContentStyle}>
      <ul>
        {CONFIG.PRIVACY_PAGE.PRIVACY_ITEMS.map((item) => (
          <li style={{ margin: 10 }}>{item.text}</li>
        ))}
      </ul>
    </div>
    <Footer style={{ alignSelf: "stretch" }} />
  </div>
);

export default Privacy;
