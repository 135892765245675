import React, { useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";

//  HOC to scroll to top of page on route change
const ScrollToTop = ({ children, history }) => {
  useEffect(() => {
    const removeListener = history.listen(() => window.scrollTo(0, 0));
    return () => removeListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
