import React from "react";
import Footer from "../components/Footer";
import { AboutPageStyle, ContentStyle } from "../styles";
import CONFIG from "../CONFIG";

const AboutGeoProtect = () => (
  <div style={AboutPageStyle}>
    <h1>{CONFIG.GEOPROTECT_ABOUT.HEADER}</h1>
    {CONFIG.GEOPROTECT_ABOUT.SUBHEADER()}
    <div style={ContentStyle}>{CONFIG.GEOPROTECT_ABOUT.CONTENT()}</div>
    <Footer style={{ alignSelf: "stretch" }} />
  </div>
);

export default AboutGeoProtect;
