export const StyleGenerator = (styleString) => JSON.parse(styleString);

export const colors = {
  primary: "#254C62",
  darkenedPrimary: "#143551",
  highlight: "#EDAE49",
  textInvertColor: "#FEFEFE",
  coloredBG: "#60737F",
  textColor: "#000",
  error: "red",
  accent: "tomato",
};

export const ErrorStyle = {
  color: colors.error,
};

export const LinkStyle = {
  color: colors.highlight,
};

export const isMobile = () => window.matchMedia("(max-width: 600px)").matches;

export const AboutPageStyle = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "100%",
  marginTop: "20px",
  width: "100%",
};
// This allows the footer to be pushed to the bottom on safari
if (isMobile()) AboutPageStyle.height = "initial";

export const ContentStyle = {
  margin: 20,
  textAlign: "justify",
  width: isMobile() ? "90%" : "80%",
  minHeight: "100%",
  maxWidth: 800,
  minWidth: 200,
  flexGrow: 3,
};
