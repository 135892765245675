import React, { Fragment } from "react";
// Components
import Hero from "../components/Hero";
import Body from "../components/Body";
import Footer from "../components/Footer";

const Main = ({ share }) => (
  <Fragment>
    <Hero share={share} />
    <Body />
    <Footer />
  </Fragment>
);

export default Main;
