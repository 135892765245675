import React, { useEffect, useState, Fragment } from "react";

const zeroPad = (value, length = 2) => {
  const strValue = String(value);
  if (length === 0) return strValue;
  const match = strValue.match(/(.*?)([0-9]+)(.*)/);
  const prefix = match ? match[1] : "";
  const suffix = match ? match[3] : "";
  const strNo = match ? match[2] : strValue;
  const paddedNo =
    strNo.length >= length
      ? strNo
      : ([...Array(length)].map(() => "0").join("") + strNo).slice(length * -1);
  return `${prefix}${paddedNo}${suffix}`;
};

const CountdownTimer = ({ prefix, target }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: "...",
    minutes: "...",
    seconds: "...",
  });

  useEffect(() => {
    const timeUpdateInterval = setInterval(() => {
      const time = new Date(target).getTime() - new Date().getTime();
      setTimeLeft({
        days: Math.floor(time / (1000 * 60 * 60 * 24)),
        hours: Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: zeroPad(Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))),
        seconds: zeroPad(Math.floor((time % (1000 * 60)) / 1000)),
      });
    }, 1000);
    return () => clearTimeout(timeUpdateInterval);
  }, [target]);

  const { days, hours, minutes, seconds } = timeLeft;
  return (
    <Fragment>
      {`${prefix}${days !== 0 ? `${days}:` : ""}${hours}:${minutes}:${seconds}`}
    </Fragment>
  );
};

export default CountdownTimer;
