import React from "react";
import { colors } from "../../../styles";

const { darkenedPrimary } = colors;
const InputStyle = {
  padding: 8,
  borderRadius: 4,
  margin: "5px 0 15px 0",
  boxShadow: `3px 3px ${darkenedPrimary}`,
};

const TextInput = ({ style, ...rest }) => {
  return <input style={{ ...InputStyle, ...style }} type="text" {...rest} />;
};

export default TextInput;
