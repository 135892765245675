// Reusable component to prompt user for geolocation
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

function cloneAsObject(obj) {
  if (obj === null || !(obj instanceof Object)) return obj;
  var temp = obj instanceof Array ? [] : {};
  // ReSharper disable once MissingHasOwnPropertyInForeach
  for (var key in obj) {
    temp[key] = cloneAsObject(obj[key]);
  }
  return temp;
}

const ERROR_CODES = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
  UNSUPPORTED: 4,
};

const Geolocator = ({
  setGeolocation,
  setError,
  setLoading,
  options,
  requiredAccuracy,
  retries: allowedRetries,
  children,
}) => {
  const [retries, setRetries] = useState(0); // number of times to retry

  useEffect(() => {
    let watchId;
    // On geolocation success
    const success = (pos) => {
      const {
        coords: {
          latitude,
          longitude,
          altitude,
          accuracy,
          altitudeAccuracy,
          heading,
          speed,
        },
      } = pos;
      // Move into object to be pushed to backend
      const geolocation = {
        latitude,
        longitude,
        altitude,
        accuracy,
        altitudeAccuracy,
        heading,
        speed,
      };
      setLoading(true);
      setGeolocation(geolocation);
      if (geolocation.accuracy <= requiredAccuracy) {
        navigator.geolocation.clearWatch(watchId);
        setLoading(false);
      } else if (retries === allowedRetries) {
        // We have reached the maximum retries
        setError({
          code: 3,
          message: `Position acquisition timed out - max retries - ${retries}`,
          ...ERROR_CODES,
        });
      } else {
        setRetries(retries + 1);
      }
    };

    // On geolocation error
    const error = (error) => {
      setError(cloneAsObject(error));
      navigator.geolocation.clearWatch(watchId);
      setLoading(false);
    };

    const getGeolocation = () => {
      navigator.geolocation.watchPosition(success, error, options);
    };

    // Ensure navigator exists
    if (typeof navigator !== "undefined" && navigator.geolocation) {
      watchId = getGeolocation();
    } else {
      // custom error to handle when navigator is undefined
      setError({
        code: 4,
        message: "This browser or device does not support geolocation",
        ...ERROR_CODES,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options,
    setError,
    setGeolocation,
    setLoading,
    requiredAccuracy,
    allowedRetries,
  ]);

  return children || null;
};

Geolocator.defaultProps = {
  options: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity,
    watchPosition: true,
  },
  requiredAccuracy: 150, // distance in meters required to be a valid geolocation
  retries: 10, // Number of times to watch geolocation accuracy change before stopping
};

Geolocator.propTypes = {
  setGeolocation: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  options: PropTypes.shape({
    enableHighAccuracy: PropTypes.bool.isRequired,
    maximumAge: PropTypes.number.isRequired,
    timeout: PropTypes.number.isRequired,
    watchPosition: PropTypes.bool.isRequired,
  }),
  requiredAccuracy: PropTypes.number.isRequired,
  retries: PropTypes.number.isRequired,
  children: PropTypes.element,
};

export default Geolocator;
