import React from "react";
import Footer from "../components/Footer";
import { AboutPageStyle, ContentStyle } from "../styles";
import CONFIG from "../CONFIG";

const About = () => (
  <div style={AboutPageStyle}>
    <h1>{CONFIG.ABOUT_PAGE.HEADER}</h1>
    <div style={ContentStyle}>{CONFIG.ABOUT_PAGE.CONTENT()}</div>
    <Footer style={{ alignSelf: "stretch" }} />
  </div>
);

export default About;
