import React from "react";
import { colors } from "../../styles";
import CONFIG from "../../CONFIG";

const { primary, textInvertColor } = colors;

const NotFoundPage = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexDirection: "column",
  color: textInvertColor,
  backgroundColor: primary,
};

const TextStyle = {
  margin: "0 0 15px 0",
};

const FileNotFound = (props) => {
  return (
    <div style={NotFoundPage}>
      <h1>{CONFIG.LINK_EXPIRED.HEADER}</h1>
      <div style={TextStyle}>{CONFIG.LINK_EXPIRED.SUB}</div>
      {CONFIG.LINK_EXPIRED.BUTTON()}
    </div>
  );
};

export default FileNotFound;
