import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
// components
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
// Pages
import Main from "./pages/Main";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Retrieve from "./pages/Retrieve";
import AboutGeoProtect from "./pages/AboutGeoProtect";
import GeoProtectTroubleshooting from "./pages/GeoProtectTroubleshooting";
import Instrumented from "./HOC/Instrumented";
import { postMetadata, createShare } from "./utils";

const AppStyle = {
  textAlign: "center",
  width: "100%",
  height: "100%",
};

const App = () => {
  const share = async () => {
    try {
      await postMetadata({ msg: "Share button clicked" });
      await createShare();
    } catch (e) {
      window.alert(
        "We are experiencing issues at this time, please try again later."
      );
    }
  };

  return (
    <div style={AppStyle}>
      <BrowserRouter>
        <Instrumented>
          <Header share={share} />
          <ScrollToTop>
            <Switch>
              <Route path="/" exact component={() => <Main share={share} />} />
              <Route path="/about" exact component={About} />
              <Route path="/privacy" exact component={Privacy} />
              <Route path="/terms" exact component={Terms} />
              <Route
                path="/about/geoprotect"
                exact
                component={AboutGeoProtect}
              />
              <Route
                path="/geoprotect/troubleshooting"
                component={GeoProtectTroubleshooting}
                exact
              />
              <Route path="/file/:id" component={Retrieve} />
              <Redirect to="/" />
            </Switch>
          </ScrollToTop>
        </Instrumented>
      </BrowserRouter>
    </div>
  );
};

export default App;
