import axios from "axios";

export function cloneAsObject(obj) {
  if (obj === null || !(obj instanceof Object)) {
    return obj;
  }
  var temp = obj instanceof Array ? [] : {};
  // ReSharper disable once MissingHasOwnPropertyInForeach
  for (var key in obj) {
    temp[key] = cloneAsObject(obj[key]);
  }
  return temp;
}

export const userIsOnIOS = !!(
  navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
);

// API Utils
export const postMetadata = async (data) => await axios.post("/reflect", data);

export const postPassword = async (data) => await axios.post("/password", data);

export const getFileMetadata = async (id) => await axios.post("/meta", { id });

export const getFiles = async (data) => await axios.post("/retrieve", data);

export const deleteFiles = async (id) => await axios.post("/delete", { id });

export const createShare = async () => await axios.post("/create");
